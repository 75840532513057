@import "_recipes";
@import "_variables";


    .domain-mesa-corporativa {
    	.gadget-formulario {
    		.entitytype-help-form {
    			.pre-instructions {
    				top: -80px;
    				h3 {
    					display: inline-block;
					    padding: 20px 40px;
					    margin: 0 0 15px;
					    text-transform: uppercase;
					    font-size: 24px; 
					    .show-en {
			    			display: none;
			    		}
    				}
    			}
    		}
    	}
    }

//* ************** SLIDESHOW DEFAULT ***** */
	.gadget-formulario.view-mode-gt_dropdown {
    body:not(.page-node-edit):not(.page-node-add) & {
    	position: fixed; bottom: 0; left: 0; right: 0;
    	z-index: 9;
    	height: 0;
    	&.turn-to-absolute { position: absolute; bottom: -120px;}
    }
    margin: 0;
    @include transition(height 0.5s ease-in-out 0s);
    &.hide-form {
    	body:not(.page-node-edit):not(.page-node-add) & { height: 135px;}
    }
    background-color: $corporativoSecundario;
    color: $white;
    a { color: inherit;}
    .ds-form { padding: 0;}
    .field-type-blockreference {
    	> .field-items {
	      @extend .container-width;
	      > .field-item {
	        position: relative;
	        padding: 20px 40px 20px;
	      }
	    }
    }
    

    .pre-intructions {
      position: absolute; top: 0px; left: 0; right: 0;
      @include transition(top 0.5s ease-in-out 0s);
      cursor: pointer;
      .scrolled & { top: -65px;}
      h3 {
        background-color: $corporativoSecundario;
        color: $white;
      }
    }
    &:not(.hide-form) {
    	.pre-intructions h3 { background-color: $corporativoPrincipal;}
    }
    &.hide-form {
      .pre-intructions { top: -65px;}
    }
	}

//* ************** RESPONSIVE ***** */
	@media all and (max-width: 899px) {
		.gadget-formulario.view-mode-gt_dropdown {
			&.hide-form {
				height: 175px;
				.pre-intructions { top: -59px;}
			}
			.pre-intructions {
				.scrolled & { top: -59px;}
				h3 { padding: 16px 20px 10px;}
			}
			.ds-form {
				.group-left { width: 55%;}
				.group-middle, .group-right { width: 45%;}
				.form-wrapper {
					.form-item {
						&:not(.form-item-field-bool1-und-und) { margin-right: 10px;}
						&.form-type-textarea { margin-right: 0;}
						&.field-tye-list-boolean { margin-top: 15px; margin-bottom: 10px;}
						&.form-item-field-bool1-und-und { margin-left: 0; margin-top: 10px;}
					}
				}
			}
			.form-actions { margin-bottom: -5px;}
		}
	}
	@media all and (max-width: 767px) {
		.gadget-formulario.view-mode-gt_dropdown {
			&.hide-form { height: 260px;}
			.field-type-blockreference {
				> .field-items {
					> .field-item { padding: 14px 10px 14px;}
				}
			}
			.ds-form {
				.group-left {
					width: 45%;
					.form-wrapper { width: 100%;}
				}
				.group-middle, .group-right { width: 55%;}
				.form-wrapper {
				}
				.form-item {
					textarea.form-textarea { min-height: 100px;}
				}
			}
			.form-actions { width: 55%;}
		}
	}
	@media all and (max-width: 479px) {
		.gadget-formulario.view-mode-gt_dropdown {
			.pre-intructions {
				.scrolled & { top: -60px;}
				h3 {
					min-width: 200px; margin-left: auto; margin-right: auto;
					font-size: 20px
				}
			}
			&.hide-form { height: 415px;}
			.ds-form {
				.group-left {	width: 100%;}
				.group-middle, .group-right { width: 100%;}
				.form-wrapper {
					.form-item.form-item:not(.form-item-field-bool1-und-und) { margin-right: 0px;}
				}
			}
			.form-actions {
				position: relative;
				width: 100%;
			}
		}
	}