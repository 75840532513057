@import "_recipes";
@import "_variables";


.gadget-item {
	display: block; margin-top: 13px; margin-bottom: 13px;
	&.view-mode-full {
		position: relative; font-size: 0;
		@include clearfix;
		.field-name-field-hiwc-covers-image {
			display: inline-block; margin-right: 20px;
			width: calc(50% - 20px); vertical-align: middle;
			float: inherit;  max-width: 100%; color: #33302e;
			// float: left; margin-right: 20px;
			// max-width: 48%;
		}
		.field-name-field-hiwc-covers-html {
			display: inline-block; margin-left: 20px;
			width: calc(50% - 20px); vertical-align: middle;
			line-height: 1.4em;
			font-family: $medium; font-size: 18px; color: #33302e;
		}

	}
	img { width: 100%;}
  &.view-mode-big,
  &.view-mode-medium,
  &.view-mode-small {
  	margin-left: auto; margin-right: auto;
  	text-align: center;
	  .group-link {
	  	display: table; width: 100%;
	  	> * { display: table-row;}
	    .field-name-field-hiwc-covers-title {
	    	.field-item { display: table-cell; vertical-align: middle;}
	    }
	  }
	  > .field-name-field-hiwc-covers-title {
	  	display: table; width: 100%;
	  	.field-item { display: table-cell; vertical-align: middle;}
	  }
	  .field-name-field-hiwc-covers-title {
	  	box-sizing: border-box;
	  	padding: 0 13px;
	    margin: 0;
	    color: $white; font-size: 24px; text-transform: uppercase; line-height: 1.05em; font-weight: normal;
	    a { color: inherit;}
	    .field-item {
				display: table-cell; vertical-align: middle;
	    	background-color: #474543;
	    	padding: 14px; margin: 0 0;
		    height: 2.2em;
		  }
	  }
	  .field-name-field-hiwc-covers-image { margin-left: 13px; margin-right: 13px;}
	  .field-name-field-hiwc-covers-html {
			padding: 14px;
	    color: $text; font-size: 18px;
	    * { margin: 0;}
	  }
 	}
  &.view-mode-big {
  	max-width: 1180px; margin-left: auto; margin-right: auto;
  }
  &.view-mode-medium {
  	max-width: 585px; //margin: 0 auto;
  }
  &.view-mode-small {
  	max-width: 370px;
  	.field-name-field-hiwc-covers-title { font-size: 20px;}
  }
}


.front {
	.group-container {
		.gl-row-50-50 {
			.gl-hollow-50:first-child {
				.gadget-item.view-mode-medium {
					margin-right: 12px;
				}
			}
			.gl-hollow-50:nth-child(2) {
				.gadget-item.view-mode-medium {
					margin-left: 12px;
				}
			}
		}
	}
}

//* ********** RESPONSIVE
	@media all and (max-width: 767px) {
		.gadget-item {
			&.view-mode-full {
				.field-name-field-hiwc-covers-image {
					float: none;
					margin-right: 0px; margin-bottom: 24px;
					max-width: 100%;
				}
			}
		}
	}


	@media all and (max-width: 639px) {
		.front {
			.group-container {
				.gl-row-50-50 {
					.gl-hollow-50:first-child {
						.gadget-item.view-mode-medium {
							margin-right: 0px;
						}
					}
					.gl-hollow-50:nth-child(2) {
						.gadget-item.view-mode-medium {
							margin-left: 0px;
						}
					}
				}
			}
		}
	}