@import "_recipes";
@import "_variables";


.front .title-h1 {
	display: none;
}
//* ************** SLIDESHOW DEFAULT ***** */
	.gadget-galeria {
		&.view-mode-slider, &.view-mode-slider_interior,
		&.view-mode-slider-thumbnails { display: block;}
	}

	.gadget-galeria {
		&.view-mode-slider_interior {
			margin-bottom: 20px;
		}
	}
	@media all and (max-width: 1368px) {
		.gadget-galeria.view-mode-slider .field-slideshow {
    height: 500px; }
		.field-slideshow-slide { img { 
			min-height: 500px;
      object-fit: cover;
      max-width: 100%;
		}}
	}	
	

	@media all and (max-width: 1279px) {
		.gadget-galeria.view-mode-slider .field-slideshow {
    height: 450px; }
		.field-slideshow-slide { img { 
			min-height: 450px;
      object-fit: cover;
      max-width: 100%;
		}}
	}
	.field-slideshow-wrapper {
		position: relative;
		.field-slideshow-controls {
			a {
				position: absolute; top: 50%;
				z-index: 9;
				font-size: 0;
				&:before {
					@include icomoon(30px);
					content: "";
					display: block;
					color: $white;
				}
				&.prev {
					left: 20px;
					&:before { content: '\e905';}
				}
				&.next {
					right: 20px;
					&:before { content: '\e906';}
				}
			}
		}

		.field-slideshow-caption, .field-name-field-hiwc-covers-caption {
			position: absolute; bottom: 0px; left: 0; right: 0;
			background-color: rgba(0, 0, 0, 0.7);

			// position: absolute;
	  //   bottom: 0px;
	  //   left: 0;
	  //   right: 0;
	  //   padding: 20px 0;
	  //   background-color: rgba(0, 0, 0, 0.7);
			.field-item {
				@extend .container-width;
				display: block;
				font-size: 40px; color: $corporativoPrincipal; text-align: center; line-height: 1.5em;
				padding: 20px 0;
				h1 {
					font-size: 40px; color: $white; text-align: center; line-height: 1.4em;
					margin: 0; font-weight: 300;
				}


				.red-text {
					color: red;
				}

				.small-caption {
					font-size: 28px; color: $white; line-height: 1.1em;
				}

				// display: block;
	   //    font-size: 40px;
	   //    color: #FFFFFF;
	   //    text-align: center;
	   //    line-height: 1.1em;
				// span { font-size: 57px; color: $white; line-height: 1.1em;}
			}
		}
		.field-slideshow-caption {
			display: none;
		}
	}

	.field-slideshow-carousel-wrapper {
		position: relative;
		padding: 10px 26px 0 21px;
		.carousel-prev,
		.carousel-next {
			position: absolute; top: 10px; bottom: 0;
			width: 18px; height: auto;
		  margin: 0;
		  background-color: $corporativoSecundario;
		  background-repeat: no-repeat;
		  background-position: center center;
		  font-size: 0; text-align: center;
		  &:before {
		  	@include icomoon(16px);
		  	color: $white;
		  	line-height: 130px;
		  }
			&.hidden {
				// display: block;
				// visibility: visible;
				//pointer-events: none;
			}
		}
		.carousel-prev {
			left: 0;
			&:before { content: '\e905';}
		}
		.carousel-next {
			right: 0;
			&:before { content: '\e906';}
		}
		.field-slideshow-pager {
			font-size: 0;
			li {
				display: inline-block; vertical-align: middle;
				width: 25%;
				margin: 0; padding: 0;
				a { display: block; margin: 0 5px;}
			}
		}
	}

//* *************** RESPONSIVE ***** */
	@media all and (max-width: 1023px) {
		.gadget-galeria {
			.field-slideshow-wrapper {
				.field-slideshow-caption {
					bottom: 35px;
					.field-slideshow-caption-text {
						font-size: 32px;
						span { font-size: 46px;}
					}
				}
			}
		}
	}
	@media all and (max-width: 799px) {
		.gadget-galeria {
			.field-slideshow-wrapper {
				.field-slideshow-caption {
					bottom: 20px;
					.field-slideshow-caption-text {
						font-size: 28px;
						span { font-size: 36px;}
					}
				}
			}
		}
	}

	@media all and (max-width: 768px){
		.field-slideshow-wrapper {
			.field-slideshow-caption, .field-name-field-hiwc-covers-caption {
				.field-item {
					h1 {
						font-size: 32px;
					}
					.small-caption {
						font-size: 28px;
					}
				}
			}
		}
	}