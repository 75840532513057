@import "_recipes";
@import "_variables";

html {
	width: 100%; height: 100%;
	-webkit-overflow-scrolling: touch; //iPhone scroll
}

body {
	width: 100%; height: 100%;
	margin: 0;
}

#container {
	padding-top: 0px;
}

a {
	outline: none;
	color: $link; text-decoration: none;
	@include transition(all 0.2s ease-in-out 0s);
	&:hover { color: $link_hover;}
}
input[type="submit"] { @include transition(all 0.5s ease-in-out 0s);}

input, select, a { outline: none;}

img {
	display: block;
	max-width: 100%; height: auto;
	.gm-style & { max-width: 1024px;}
}


// *  ******************** STRONG */
	b, strong { font-weight: bold;}

// * ******************** PAGINATION */
	.item-list {
		.pager {  margin: 10px 0;
			li {
				display: inline-block; vertical-align: middle;
				margin: 0; padding: 6px 6px 5px;
				color: $white; font-size: 18px;
				a {
					display: inline-block; vertical-align: middle;
					color: $text; font-family: $medium; font-size: 16px;
					margin: 1px 0 0; padding: 5px 8px;
					&:hover { color: $black;}
				}
			}
			li.pager-current {
				margin: -1px 0 0; padding: 7px 8px;
				font-weight: normal;
			}
			.pager-ellipsis { color: $text;}
		}
	}

// *  ******************** THROBBER */
	@include keyframes(spin) {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(359deg); }
	}

	.ajax-progress .throbber,
	.flag-waiting .flag-throbber {
		@include animation("spin 1.0s infinite linear");
		@include opacity(0.6);
		@include bg-image(throbber);
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 25px auto;
		position: absolute; top: 0; left: 0;
		margin: 0; padding: 0;
		width: 25px; height: 25px;
		overflow: hidden;
		text-align: center;
	}

// *  ******************** MENSAJES */
	h2.messages-label { display: none; }

	#better-messages-default div.messages,
	div.messages {
		@include clearfix;
		margin:  15px 0;
		padding: 15px 10px 15px 100px;
		@include bg-image(info);
		background-color: $green;
		background-repeat: no-repeat;
		background-position: 35px 11px;
		background-size: 38px auto;
		min-height: 70px; width: auto;
		border: 0;
		@include border-top-radius(5px);
  	@include border-bottom-radius(5px);
		font-size: 12px; color: $black;
		line-height: 1.2;
		word-break: break-word;
		a {
			color: $white;
			&:hover { color: $white;}
		}
		p { margin: 5px 0;}
		ul {
			margin: 0 0 0 20px; padding: 0;
			list-style: none outside none;
			li {
				margin: 0 0 10px;
				list-style: none outside none;
			}
		}
	}

	ul li.leaf { list-style-image: none; list-style-type: none;}

	#better-messages-default div.messages.status,
	div.messages.status {
		@include bg-image(status);
		background-color: $black;
		background-repeat: no-repeat;
		background-position: 15px 30px;
		background-size: 81px auto;
		color: $white;
	}
	#better-messages-default div.messages.error,
	div.messages.error {
		@include bg-image(error);
		background-color: $red;
		background-repeat: no-repeat;
		background-position: 15px 15px;
		background-size: 71px auto;
		a {
			color: #FFFFFF;
			&:hover { color: #000000; }
		}
	}
	#better-messages-default div.messages.warning,
	div.messages.warning {
		@include bg-image(warning);
		background-color: $orange;
		background-repeat: no-repeat;
		background-position: 15px 10px;
		background-size: 68px auto;
		a {
			color: #FFFFFF;
			&:hover { color: #000000; }
		}
	}

// * *********************** FORMULARIOS */
	.form-item {
		margin: 10px 0 12px;
		padding: 0;
		label {
			display: block;
			padding: 0 0 3px 0px;
		}
		input.form-text,
		textarea.form-textarea {
			box-sizing: border-box;
			//@include css3pie(5px);
			background: $white;
			border: 1px solid $grey;
			padding: 12px 12px 10px; margin: 0;
			width: 100%;
			resize: none;
			font-size: 16px; color: $dark-blue;
			outline: 0 none;
		}
		//textarea.form-textarea { width: 99%; }

		input.form-text:focus,
		textarea.form-textarea:focus {
			border-color: $black;
			color: $black;
		}
		.grippie { display: none;}
	  	.description, .tips { font-size: 0.8em;}
		select {
			background: $white;
			border: 1px solid $grey;
			padding: 12px 12px 10px; margin: 0;
			font-size: 16px; color: $dark-blue;
			outline: 0 none;
			.entityform & { width: 100%;}
			&:active, &:focus, &:hover {
				background: $white;
				border-color: $black;
				color: $black;
			}
		}
		input.error { border: 2px solid red;}
	}

	.views-exposed-form label,
	.views-exposed-form .nolabel {
		font-size: 13px;
		display: block;
		padding: 0 0 3px 5px;
		font-weight: normal;
		font-family: $medium;
	}

	.form-actions { text-align: right;}
	input.form-submit {
		//@include css3pie(2px);
		float: none;
		background: $corporativoPrincipal;
		border: 1px solid $corporativoPrincipal;
		margin: auto; padding: 10px 16px 8px;
		font-size: 16px; color: $white; font-family: $medium; //text-transform: uppercase;
		cursor: pointer;
		&:active, &:focus, &:hover {
			background: $white;
			color: $corporativoPrincipal;
		}
	}
	.form-item .description { padding: 5px; }

// * **** FILTROS **** */
	.views-exposed-form .views-exposed-widget .form-submit {
		margin-top: 10px;
	}


// * **** CHECKBOXES **** */
	.form-type-checkbox {
		margin-top: 0.6em; margin-bottom: 0.6em;
		input[type="checkbox"] {
			display: none;
		}
		label {
			cursor: pointer;
			display: inline-block; vertical-align: middle;
			padding: 3px 0 0 25px;
			position: relative;
		}
		label:before {
			content: "";
	    //@include css3pie(3px);
			display: inline-block; vertical-align: middle;
			width: 16px;
			height: 16px;
			margin-right: 10px;
			position: absolute;
			left: 0;
			top: 1px;
			background-color: #FFF;
			box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.4);
			border: 1px solid $black;
		}
		input[type=checkbox]:checked + label:after {
			content: '';
	    //@include css3pie(3px);
			display: block;
			top: 1px;
			left: 0px;
			@include bg-image(check);
			background-color: $corporativoPrincipal;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 15px 12px;
	    	border: 1px solid $black;
			position: absolute;
			line-height: 1;
			width: 16px;
			height: 16px;
		}
	}

// * **** RADIOS **** */
	.form-type-radio {
		input[type="radio"] {
			display: none;
		}
		label:before {
			@include border-top-radius(8px);
  		@include border-bottom-radius(8px);
		}
		label {
			color: $black;
			cursor: pointer;
			display: inline-block; vertical-align: middle;
			padding: 3px 3px 0 26px;
			position: relative;
		}
		label:before {
			display: inline-block; vertical-align: middle;
			position: absolute;
			top: 0px;
			left: 0;
			margin: 4px;
			width: 14px;
			height: 14px;
			background: #FFF;
			content: "";
			@include border-top-radius(50%);
  		@include border-bottom-radius(50%);
			box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.4);
			border: 1px solid $black;
		}
		input[type="radio"]:checked + label:after {
			content: '';
			position: absolute;
			display: block;
			top: 6px;
			left: 6px;
			@include bg-image(radio);
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 8px 8px;
			line-height: 1;
			width: 12px;
			height: 12px;
		}
	}

	.form-error, .form-item .error {
		//border: 2px solid #d2242a !important;
		border: 2px solid #d2242a;
		color: #d2242a !important;
	}

.modal-content {
	.form-type-checkbox {
		input[type="checkbox"] {
			display: block;
		}
	}
	.form-type-radio {
		input[type="radio"] {
			display: block;
		}
	}
}


// *  ******************** TABS */
	#center {
		position: relative;
	}
	ul.tabs.primary,
	.left-corner > ul.action-links {
		position: absolute;
		right: 0;
		//top: -12px;
		z-index: 100;
		border: 0;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	ul.tabs.primary {
		right: 122px;
	}

	ul.tabs.primary li,
	.left-corner > ul.action-links li {
		float: left;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	ul.tabs.primary a,
	.left-corner > ul.action-links a,
	ul.primary li.active a,
	.left-corner > ul.action-links li.active a {
		font-size: 12px;
		font-family: $medium;
		background: $black;
		border: 0;
		color: $white;
		display: block;
		margin: 0 1px 0 0;
		padding: 4px 12px;
		text-decoration: none;
		text-transform: uppercase;
		line-height: 1.2em;
	}

	ul.tabs.primary a:hover,
	ul.tabs.primary a.active,
	.left-corner > ul.action-links a.active,
	.left-corner > ul.action-links a:hover {
		color: $white;
		background: $corporativoPrincipal;
	}

// *  ******************** FIELD COLLECTION RESET ***** */
	.field-collection-container {
	    border-bottom: 0 none;
	    margin-bottom: 0;
	    .field-items {
	        .field-item {
	            margin-bottom: 0;
	        }
	    }
	}

	.field-collection-view {
    border-bottom: 0 none;
    margin: 0;
		padding: 0;
	}

// *  ******************** TABS RESET ***** */
	.field-collection-tabs {
		padding: 0;
		@include border-top-radius(0px);
		@include border-bottom-radius(0px);
		border: 0 none;
		background: none transparent;
		.item-list {
			.ui-tabs-nav {
				padding: 0;
				@include border-top-radius(0px);
				@include border-bottom-radius(0px);
				border: 0 none;
				background: 0 none;
				font-weight: normal;
				li {
					padding: 0;
					margin: 0;
					background: 0 none;
					border: 0 none;
					@include border-top-radius(0px);
					@include border-bottom-radius(0px);
					a {

					}
				}
			}
		}
		.ui-tabs-panel {
			padding: 0;
			border: 0 none;
		}
	}

// *  ******************** FLEXSLIDER RESET */
	.flexslider {
		margin: 0;
		background: 0 none;
		border: 0 none;
		@include border-top-radius(8px);
  	@include  border-bottom-radius(8px);
		box-shadow: none;
		.flex-control-nav {
			z-index: 9;
			bottom: 20px;
			a {
				box-shadow: inset 0px 0px 6px -1px rgba(255, 255, 255, 0.7);
			}
		}
		.flex-direction-nav {
			a {
				display: block;
				opacity: 1;
				&.flex-prev { left: 20px;}
				&.flex-next { right: 20px;}
			}
		}
    .flex-caption {
        @include invisible;
    }
	}


// *  ******************** RESPONSIVE */
	.responsive_icon {
		display: none;
	}