@import "_recipes";
@import "_variables";


//* ************** SLIDESHOW DEFAULT ***** */
	.gadget-detalle {
		display: block;
		margin-top: 30px; margin-bottom: 50px;
		&.full, &.view-mode-full_trajes, &.view-mode-full {
			.group-header {
				padding: 14px 26px 12px 100px;
				background-color: $corporativoSecundario;
				> * { display: inline-block; vertical-align: bottom;}
				.field-name-field-hiwc-covers-caption {
					width: 60%;
				}
				.field-name-field-text1-lang { width: 40%;}
				.field-name-field-trajes-tax {
					display: inline-block;
    			font-size: 0;
				}
			}
			.group-left,
			.group-right { 
				margin-top: 20px; margin-bottom: 20px;
				.field-name-field-longtext1-lang {
					margin: 10px 0;
					border-bottom: 1px solid #efeeee;
					h4.title-detail {
						color: red;
						font-size: 14px;
						text-align: left;
					}
				}
			}
			.group-left {
				float: left;
				width: 60%;
				> div { margin-left: 100px; margin-right: 26px;}
				.field-name-field-enlace, .field-name-field-hiwc-covers-link {
					.field-item {
						display: inline-block; margin: 0 10px;
						a {
							float: none;
					    background: #cc0000;
					    border: 1px solid #cc0000;
					    margin: auto;
					    padding: 10px 16px 8px;
					    font-size: 18px;
					    color: #FFFFFF;
					    font-family: "futuramedium", Arial, sans-serif;
					    cursor: pointer;
					    &:hover {
					    	background: #FFFFFF;
	    					color: #cc0000;
					    }
						}
					}
				}
				+ .group-right { 
					float: right;
					width: 40%;
				}
			}
			.field-name-field-hiwc-covers-caption {
				margin: 0;
				font-size: 18px; color: $white; line-height: 1.05em; font-weight: normal;
				* { margin: 0;}
				strong { font-size: 30px; line-height: 1.05em; font-weight: normal;}
			}
			.field-name-field-text1-lang {
				text-align: right;
				color: #e3b53b; font-size: 18px;
			}
			// .field-slideshow-wrapper {

			// 	.field-slideshow-controls {
			// 		a {
			// 			&:before {
			// 				display: block;
			// 				margin-top: -55px;
			// 				width: 28px; height: 110px;
			// 				background-color: $white;
			// 				color: $text; line-height: 110px; font-weight: bold;
			// 			}
			// 		}
			// 		.prev { left: 0;}
			// 		.next { right: 0;}
			// 	}

			// 	.field-slideshow-pager {
			// 		width: 100%; z-index: 999999;
			//     margin-left: 0px;
			//     padding-left: 0px;
			//     position: absolute;
			//     bottom: 3px;
			//     text-align: center;
			// 		a {
			// 			font-size: 0; display: inline-block; margin: 0 5px;
			// 			width: 16px; height: 6px; opacity: 0.9;
			// 			background-color: $white; border: 1px solid #333;
			// 			&.activeSlide {
			// 				background-color: $corporativoPrincipal;
			// 			}
			// 		}
			// 	}
			// }
		}
		&.view-mode-full_trajes {
			.field-slideshow-wrapper {

				.field-slideshow-controls {
					a {
						&:before {
							display: block;
							margin-top: -55px;
							width: 28px; height: 110px;
							background-color: $white;
							color: $text; line-height: 110px; font-weight: bold;
						}
					}
					.prev { left: 0;}
					.next { right: 0;}
				}

				.field-slideshow-pager {
					width: 100%; z-index: 999999;
			    margin-left: 0px;
			    padding-left: 0px;
			    position: absolute;
			    bottom: 3px;
			    text-align: center;
					a {
						font-size: 0; display: inline-block; margin: 0 5px;
						width: 16px; height: 6px; opacity: 0.9;
						background-color: $white;
						&.activeSlide {
							background-color: $corporativoPrincipal;
						}
					}
				}
			}
		}
	}

	// HTML TEMPLATE
	.detail-item {
		display: inline-block; vertical-align: top;
		width: 47.5%;
		margin-bottom: 20px;
		font-size: 16px;
		&:nth-child(2n+1) { margin-right: 1.5%;}
		&:nth-child(2n+2) { margin-left: 1.5%;}
		.detail-title {
			margin-top: 20px; margin-bottom: 20px;
			color: #888888;
		}
		ul {
			padding-left: 0; margin: 0 0 0 18px;
			li { margin: 10px 0;}
		}
	}


	// VIEW MENU TRAJES INTERVENCION
	.view-trajes-uniformidad {
		position: relative; max-width: 100%; margin: 0 auto;
		text-align: center;
		.views-row {
			width: auto; display: inline-block; margin: 0 10px;
			max-width: 175px;
			.vocabulary-trajes-de-intervencion, .vocabulary-trajes-uniformidad {
				cursor: pointer; text-align: center;
				h2 {
					font-size: 12px;
					text-align: center;
					a {
						pointer-events: none;
					}
				}
				.field-name-field-img1-und {
					img {
						margin: 0 auto;	
					}
				}
			}
		}
	}

//* ********** RESPONSIVE

	@media all and (max-width: 1024px) {
		.view-trajes-uniformidad {
			.views-row {
				max-width: 150px;
				.vocabulary-trajes-de-intervencion, .vocabulary-trajes-uniformidad {
					cursor: pointer; text-align: center;
					h2 {
						font-size: 16px;
						text-align: center;
						a {
							pointer-events: none;
						}
					}
					.field-name-field-img1-und {
						img {
							max-width: 100px;	
						}
					}
				}
			}
		}


		.gadget-detalle {
			&.view-mode-full, &.full, &.view-mode-full_trajes {
				position: relative; font-size: 0px;
				.group-left {
					display: inline-block; width: 60%;
					float: inherit;
					> div {
						font-size: 14px;
						margin-left: 0px;
						margin-right: 20px;
					}
				}
				.group-right {
					display: inline-block; width: 40%;
					float: inherit;
				}
			}
		}
		.gadget-detalle.view-mode-full_trajes .group-left + .group-right {
			display: inline-block; width: 40%;
			float: inherit; vertical-align: top;
		}
		.gadget-detalle.view-mode-full .group-left + .group-right {
			display: inline-block; width: 40%;
			float: inherit; vertical-align: top;
		}
	}


	@media all and (max-width: 799px) {
		.gadget-detalle {
			.group-header { padding-left: 15px;}
			.group-left > div { margin-left: 15px;}
		}
	}

	@media all and (max-width: 768px) {
		.gadget-detalle {
			&.view-mode-full, &.full, &.view-mode-full_trajes {
				position: relative; font-size: 0px;
				.group-left {
					display: block; width: 100%;
					> div {
						font-size: 14px;
						margin-left: 0px;
						margin-right: 0px;
						table {
							width: 100% !important;
						}
					}
				}
			}
		}

		.gadget-detalle.view-mode-full .group-left + .group-right {
			display: block; width: 100%; margin-top: 20px;
		}
		.gadget-detalle {
			&.view-mode-full, &.full, &.view-mode-full_trajes {
				.group-header {
					padding: 10px;
					.field-name-field-hiwc-covers-caption  {
						strong {
							font-size: 26px;
						}
					}
				}
			}
		}
	}


	@media all and (max-width: 639px) {
		.gadget-detalle {
			.group-left {
				float: none;
				width: 100%;
				+ .group-right {
					float: none;
					 width: 100%;
				}
			}
			.field-name-field-hiwc-covers-files { max-width: 475px; margin: 10px auto 0;}
		}

		.gadget-detalle {
			&.view-mode-full, &.full, &.view-mode-full_trajes {
				.group-header {
					.field-name-field-hiwc-covers-caption {
						width: 100%;
					}
					.field-name-field-text1-lang {
						width: 100%; margin-top: 10px;
					}
				}
			}
		}
	}