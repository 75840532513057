@import "_recipes";
@import "_variables";


.gadget-video {
	position: relative; display: block;
	&.view-mode-full {
		width: 100%; margin-top: -165px;
		.file-video {
			height: 780px;
	    position: relative;
	    overflow: hidden;
			iframe {
				position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
				-moz-transform: translateY(-17%);
		    -o-transform: translateY(-17%);
		    -ms-transform: translateY(-17%);
		    -webkit-transform: translateY(-17%);
		    transform: translateY(-17%);
			}
			.field-group-textos {
				position: absolute; bottom: 10px;
		    margin: 0; width: 100%;
		    h1 {
		    	font-size: 40px;
			    color: $white;
			    text-align: center;
			    line-height: 1.1em;
		    }
			}
			video {
				width: 100%;
			}
		}
	}
}




@media all and(max-width: 1680px){
	.gadget-video {
		&.view-mode-full {
			.file-video {
				height: 650px;
			}
		}
	}
}

// @media all and(max-width: 1420px){
// 	.gadget-video {
// 		&.view-mode-full {
// 			.file-video {
// 				height: 580px;
// 			}
// 		}
// 	}
// }

// @media all and(max-width: 1260px){
// 	.gadget-video {
// 		&.view-mode-full {
// 			.file-video {
// 				height: 540px;
// 			}
// 		}
// 	}
// }

// @media all and(max-width: 1160px){
// 	.gadget-video {
// 		&.view-mode-full {
// 			.file-video {
// 				height: 480px;
// 				iframe {
// 					height: 100%;
// 				}
// 			}
// 		}
// 	}
// }


@media all and(max-width: 920px){
	.gadget-video {
		&.view-mode-full {
			.file-video {
				height: 480px;
				iframe {
					height: 100%;
				}
			}
		}
	}
}




@media all and(max-width: 1024px){
	.gadget-video {
		&.view-mode-full {
			margin-top: -116px;
		}
	}
}
@media all and(max-width: 768px){
	.gadget-video {
		&.view-mode-full {
			.file-video {
				height: 420px;
				iframe {
					height: 100%;
				}
			}
		}
	}
}

@media all and(max-width: 680px){
	.gadget-video {
		&.view-mode-full {
			.file-video {
				height: 380px;
				iframe {
					height: 100%;
				}
			}
		}
	}
}